import * as React from "react"
import Container from '../components/container.js'
import styled from 'styled-components'
import '../App.css'

import "@fontsource/montserrat";
import "@fontsource/eb-garamond/400.css";
import "@fontsource/eb-garamond/400-italic.css";

const works = [
    {
      title: "Normative Foundations",
      type: "Working Monograph",
      description:
      "This monograph, the product of my DPhil dissertation, presents a theory of normativity. Its central claim is that human beings are constitutively committed to objective moral and other normative requirements.",
      order: 1
    },
    {
      title: "On Kant’s Meta-Ethics",
      type: "Working Paper",
      description:
      "Recent interpreters have understood Kant as either a meta-ethical realist or constructivist. In this paper, I argue that neither of these interpretations is correct, and I develop a third account of Kant’s meta-ethical position.",
      order: 2
    },
    {
      title: "Was Plato a Feminist?",
      type: "Paper",
      description:
      "What were Plato’s views on women? Focusing on Republic V and other works, this paper takes Gregory Vlastos’ seminal article on the topic as its foil, and it argues for a more nuanced interpretation of Plato’s views on women.",
      order: 3,
      last: true,
    },
  ]

// styles
const listStyle = {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  height: '64vh',
  overflow: 'scroll'
}

const Title = styled.h2`
  font-family: EB Garamond;
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 5px;
  font-variant: small-caps;

  @media (max-width: 500px) {
    font-size: 19px;
    width: 300px;
  }
`

const Type = styled.span`
  font-family: EB Garamond;
  font-weight: 400;
  font-size: 20px;
  font-style: italic;
  font-variant: none;

  @media (max-width: 500px) {
    font-size: 19px;
  }
`

const Body = styled.p`
  font-size: 18px;
  font-family: EB Garamond;
  font-weight: 300;
  width: 600px;
  text-align: justify;
  
  @media (max-width: 1009px) {
    width: 425px;
  }

  @media (max-width: 500px) {
    width: 300px;
    font-size: 16px;
    text-align: left;
  }
`

const Divider = styled.div`
  border-bottom: 0.5px solid black;
  width: 600px;

  @media (max-width: 1009px) {
    width: 425px;
  }

  @media (max-width: 500px) {
    width: 300px;
  }
`


const WorksPage = () => {
  return (
    <Container>
        
        <ul class="scrollbar">
          {works.map(work => (
            <li key={work.order}>
              <span>
                <Title>{work.title} &nbsp;
                  <Type>({work.type})</Type>
                </Title>

                <Body>{work.description}</Body>
              </span>
              {/* conditional ternary operator for rendering last line + padding*/}
              {work.last 
                ? 
              <div style={{padding: 100}}/>
                :
              <div>
                <div style={{padding: 7}}/>
                <Divider />
                <div style={{padding: 7}}/>
              </div>
              }
            </li>
          ))}
        </ul>

        {/* <div style={{margin: 50}}/> */}
    </Container>
  )
}

export default WorksPage